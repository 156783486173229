const { hexToRgb } = require('./utilities/colors');

/*
	The default config should typically be set as the values of
	the main solution. These can then be overwritten by the values
	coming from backend or solution-specific files (handled in the
	ThemeConfiguration.vue component). ThemeConfiguration.vue will
	automativally load the file for the current solution if named
	like `theme-configuration.[solution].js` for example the main
	solution can be overwritten with `theme-configuration.main.js`.

	You should never remove keys from this object, as Limbo Citi Baseline
	styles might be relying on them, but new keys may be added if needed.

	Only the keys in this default file will be the grounds for tailwind
	rules - overwriting configurations should never introduce new keys,
	unless they are added to this file too. Only the values of the keys
	are overwritten.
*/
module.exports = {
	// Testing only - set to false when done testing
	minify: false, // Can be turned to false for a more readable output in the style tag

	// Setup
	baseFontSize: 16, // For rem conversion
	smViewport: 375, // Lowest value clamp
	mdViewport: 1440, // Midpoint (used for both low-clamp and high-clamp)
	lgViewport: 1440, // Highest value clamp

	// Rules
	colors: {
		/*
			CSS variables will be created and used for the colors,
			which will in turn be used in the Tailwind config. Keys
			will be directly transfered to the Tailwind config.

			"primary: '#000000'" will result in the Tailwind rule:
			"primary: var(--theme-colors-primary, #000000)"

			If configuring a color with three comma-separated numbers,
			the built-in Tailwind color opacities will be used in the
			rules.
		*/
		text: hexToRgb('#363636'),

		/*
			The theme colors will be overwritten by ScopedColorTheme
			to be either red or blue (the colors below)
		*/
		theme: {
			value: 'var(--theme-colors-red)',
			isListedRgb: true,
		},
		'theme-90': {
			// Mainly used for hover
			value: 'var(--theme-colors-red-90)',
			isListedRgb: true,
		},
		'theme-80': {
			value: 'var(--theme-colors-red-80)',
			isListedRgb: true,
		},
		'theme-60': {
			value: 'var(--theme-colors-red-60)',
			isListedRgb: true,
		},
		'theme-40': {
			value: 'var(--theme-colors-red-40)',
			isListedRgb: true,
		},
		'theme-20': {
			value: 'var(--theme-colors-red-20)',
			isListedRgb: true,
		},
		'theme-10': {
			// Mainly used for hover
			value: 'var(--theme-colors-red-10)',
			isListedRgb: true,
		},
		'theme-pattern': {
			value: 'var(--theme-colors-red-pattern)',
			isListedRgb: true,
		},

		red: hexToRgb('#BF1D30'),
		'red-90': hexToRgb('#CC2B3A'), // Mainly used for hover
		'red-80': hexToRgb('#D93844'),
		'red-60': hexToRgb('#DA7C81'),
		'red-40': hexToRgb('#E7A6AB'),
		'red-20': hexToRgb('#F2D2D4'),
		'red-10': hexToRgb('#F9E9EA'), // Mainly used for hover
		'red-pattern': hexToRgb('#CA263B'),

		blue: hexToRgb('#004F9B'),
		'blue-90': hexToRgb('#085EA0'), // Mainly used for hover
		'blue-80': hexToRgb('#106CA4'),
		'blue-60': hexToRgb('#4780B2'),
		'blue-40': hexToRgb('#7598C1'),
		'blue-20': hexToRgb('#B8C9DE'),
		'blue-10': hexToRgb('#DCE4EF'), // Mainly used for hover
		'blue-pattern': hexToRgb('#115AA5'),

		gray: hexToRgb('#E8E8E8'),
		'gray-light': hexToRgb('#F1F1F1'),
		'gray-medium': hexToRgb('#DCDCDC'),
		'gray-dark': hexToRgb('#6E6E6E'),
		'gray-darker': hexToRgb('#696969'),
	},

	layout: {
		margin: {
			sm: 16,
			md: 72,
			lg: 72,
		},

		gutter: {
			sm: 0,
			md: 0,
			lg: 0,
		},

		// These rules will be turned into `X/Ycol` rules, which can then be used like `w-3/12col`.
		// There should always be at least one column, both on sm, md and lg.
		columns: {
			sm: 4,
			md: 12,
			lg: 12,
		},

		// The max value that the design can be scaled to (single value, not sm-md-lg).
		// The max will impact columns max scaling as well.
		// undefined equals no max.
		max: 1440,
	},

	spacing: {
		'header-icon': {
			sm: 38,
			md: 66,
			lg: 66,
		},
	},

	horizontalSpacing: {
		'5xs/h': {
			sm: 8,
			md: 8,
			lg: 8,
		},
		'4xs/h': {
			sm: 12,
			md: 12,
			lg: 12,
		},
		'3xs/h': {
			sm: 16,
			md: 16,
			lg: 16,
		},
		'2xs/h': {
			sm: 24,
			md: 24,
			lg: 24,
		},
		'xs/h': {
			sm: 24,
			md: 32,
			lg: 32,
		},
		'sm/h': {
			sm: 24,
			md: 40,
			lg: 40,
		},
		'md/h': {
			sm: 24,
			md: 64,
			lg: 64,
		},
		'lg/h': {
			sm: 80, // ?
			md: 80,
			lg: 80,
		},
		'xl/h': {
			sm: 96, // ?
			md: 96,
			lg: 96,
		},
		'2xl/h': {
			sm: 128, // ?
			md: 128,
			lg: 128,
		},
		'3xl/h': {
			sm: 160, // ?
			md: 160,
			lg: 160,
		},
		'4xl/h': {
			sm: 184, // ?
			md: 184,
			lg: 184,
		},

		'site-header-logo': {
			sm: 115,
			md: 132,
			lg: 132,
		},
		'site-header-sub-logo': {
			sm: 180,
			md: 206,
			lg: 206,
		},
		'site-header-sub-logo-en': {
			sm: 180,
			md: 210,
			lg: 210,
		},
	},

	verticalSpacing: {
		'5xs/v': {
			sm: 8,
			md: 8,
			lg: 8,
		},
		'4xs/v': {
			sm: 12,
			md: 12,
			lg: 12,
		},
		'3xs/v': {
			sm: 16,
			md: 16,
			lg: 16,
		},
		'2xs/v': {
			sm: 24,
			md: 24,
			lg: 24,
		},
		'xs/v': {
			sm: 24,
			md: 32,
			lg: 32,
		},
		'sm/v': {
			sm: 32,
			md: 40,
			lg: 40,
		},
		'md/v': {
			sm: 48, // ?
			md: 48,
			lg: 48,
		},
		'lg/v': {
			sm: 32,
			md: 64,
			lg: 64,
		},
		'xl/v': {
			sm: 60,
			md: 80,
			lg: 80,
		},
		'2xl/v': {
			sm: 60,
			md: 96,
			lg: 96,
		},
		'3xl/v': {
			sm: 128, // ?
			md: 128,
			lg: 128,
		},
		'4xl/v': {
			sm: 160, // ?
			md: 160,
			lg: 160,
		},

		'site-header': {
			sm: 72,
			md: 184,
			lg: 184,
		},
		'site-header-slim': {
			sm: 72,
			md: 80,
			lg: 80,
		},
	},

	// fontSize (and lineHeight and letterSpacing) is a special setup, as special rules are generated
	fontSize: {
		h1: {
			// CONTENT PAGE TITLE
			fontSize: {
				sm: 36,
				md: 60,
				lg: 60,
			},
			lineHeight: {
				sm: 1.12,
				md: 1.12,
				lg: 1.12,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'h1-sm': {
			// PAGE OVERVIEW
			fontSize: {
				sm: 28,
				md: 38,
				lg: 38,
			},
			lineHeight: {
				sm: 1.21,
				md: 1.21,
				lg: 1.21,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'h1-frontpage': {
			// Frontpage
			fontSize: {
				sm: 28,
				md: 45,
				lg: 45,
			},
			lineHeight: {
				sm: 1.21,
				md: 1.21,
				lg: 1.21,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h2: {
			// BLOCK TITLE
			fontSize: {
				sm: 32,
				md: 36,
				lg: 36,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h3: {
			// SEKUNDÆR OVERSKRIFT
			fontSize: {
				sm: 24,
				md: 26,
				lg: 26,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		h4: {
			// TERTIÆR OVERSKRIFT
			fontSize: {
				sm: 18,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1.32,
				md: 1.32,
				lg: 1.32,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		burger: {
			fontSize: {
				sm: 36,
				md: 52,
				lg: 52,
			},
			lineHeight: {
				sm: 1.12,
				md: 1.12,
				lg: 1.12,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'burger-sub': {
			fontSize: {
				sm: 22,
				md: 26,
				lg: 26,
			},
			lineHeight: {
				sm: 1.21,
				md: 1.21,
				lg: 1.21,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'burger-secondary': {
			fontSize: {
				sm: 18,
				md: 16,
				lg: 16,
			},
			lineHeight: {
				sm: 1.1875,
				md: 1.1875,
				lg: 1.1875,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'body-lg': {
			// STANDARD L
			fontSize: {
				sm: 16,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1.6,
				md: 1.6,
				lg: 1.6,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		body: {
			// STANDARD M
			fontSize: {
				sm: 16,
				md: 18,
				lg: 18,
			},
			lineHeight: {
				sm: 1.6,
				md: 1.6,
				lg: 1.6,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'body-sm': {
			// STANDARD S
			fontSize: {
				sm: 16,
				md: 16,
				lg: 16,
			},
			lineHeight: {
				sm: 1.6,
				md: 1.6,
				lg: 1.6,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'body-xs': {
			// STANDARD XS
			fontSize: {
				sm: 15,
				md: 15,
				lg: 15,
			},
			lineHeight: {
				sm: 1.6,
				md: 1.6,
				lg: 1.6,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		teaser: {
			// CONTENT PAGE MANCHET
			fontSize: {
				sm: 20,
				md: 28,
				lg: 28,
			},
			lineHeight: {
				sm: 1.32,
				md: 1.32,
				lg: 1.32,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'sub-teaser': {
			// CONTENT PAGE SUB MANCHET
			fontSize: {
				sm: 20,
				md: 26,
				lg: 26,
			},
			lineHeight: {
				sm: 1.44,
				md: 1.44,
				lg: 1.44,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'search-heading': {
			fontSize: {
				sm: 24,
				md: 26,
				lg: 26,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		label: {
			// STANDARD
			fontSize: {
				sm: 14,
				md: 15,
				lg: 15,
			},
			lineHeight: {
				sm: 1.12,
				md: 1.12,
				lg: 1.12,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'label-sm': {
			// ALL CAPS
			fontSize: {
				sm: 13,
				md: 13,
				lg: 13,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0.04,
				md: 0.04,
				lg: 0.04,
			},
		},
		'card-title-xl': {
			// CARD TITLES - XL
			fontSize: {
				sm: 32,
				md: 40,
				lg: 40,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'card-title-lg': {
			// CARD TITLES - L
			fontSize: {
				sm: 26,
				md: 26,
				lg: 26,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'card-title': {
			// CARD TITLES - M
			fontSize: {
				sm: 24,
				md: 24,
				lg: 24,
			},
			lineHeight: {
				sm: 1.3,
				md: 1.3,
				lg: 1.3,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'card-title-sm': {
			// CARD TITLES - S
			fontSize: {
				sm: 18,
				md: 22,
				lg: 22,
			},
			lineHeight: {
				sm: 1.32,
				md: 1.32,
				lg: 1.32,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'card-title-sm-2': {
			// CARD TITLES - S 2
			fontSize: {
				sm: 18,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1.32,
				md: 1.32,
				lg: 1.32,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'card-title-xs': {
			// CARD TITLES - XS
			fontSize: {
				sm: 16,
				md: 18,
				lg: 18,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'list-title': {
			// LIST TITLES - M
			fontSize: {
				sm: 20,
				md: 22,
				lg: 22,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'list-title-sm': {
			// LIST TITLES - S
			fontSize: {
				sm: 18,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1.4,
				md: 1.4,
				lg: 1.4,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'list-title-xs': {
			// LIST TITLES - XS
			fontSize: {
				sm: 14,
				md: 14,
				lg: 14,
			},
			lineHeight: {
				sm: 1.32,
				md: 1.32,
				lg: 1.32,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'cta-title': {
			// CTA TITLE L
			fontSize: {
				sm: 36,
				md: 52,
				lg: 52,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		input: {
			// INPUT
			fontSize: {
				sm: 16,
				md: 22,
				lg: 22,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
		'input-form': {
			fontSize: {
				sm: 20,
				md: 20,
				lg: 20,
			},
			lineHeight: {
				sm: 1.2,
				md: 1.2,
				lg: 1.2,
			},
			letterSpacing: {
				sm: 0,
				md: 0,
				lg: 0,
			},
		},
	},

	borderRadius: {
		/* possibly not needed? */
	},
};

// adjust()
// function adjust(value, factor = 0.8) {
// 	return +value * factor;
// }
